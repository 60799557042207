import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { StaticQuery, graphql } from 'gatsby';

import { RADICAL_RED, WHITE } from 'constants/colors';

const Description = styled.p`
  color: ${WHITE} !important;
  font-size: 3rem !important;
  margin-bottom: 2rem !important;

  ${breakpoint('tablet')`
    margin-top: -2.6rem !important;
  `}

  ${breakpoint('desktop')`
    margin-top: -3.6rem !important;
  `}
`;

const H2 = styled.h2`
  color: ${RADICAL_RED};
  margin-top: -1rem !important;

  ${breakpoint('mobile')`
    font-size: 3rem !important;
  `}

  ${breakpoint('tablet')`
    font-size: 7rem !important;
  `}
`;

const Wrapper = styled.div`
  display: inline-block;

  text-align: center;

  ${breakpoint('tablet')`
    padding-top: 2rem;
    text-align: left;
  `}

  ${breakpoint('desktop')`
    padding-top: 3rem;
  `}
`;

const TitleAndDescription = ({ data }) => {
  const title = data.site.siteMetadata.title;
  const description = data.site.siteMetadata.description;

  return (
    <Wrapper>
      <H2 className="ui">{title}</H2>
      <Description>{description}</Description>
    </Wrapper>
  );
};

const SiteMeta = ({ handleThemeChange }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => <TitleAndDescription data={data} />}
    />
  );
};

export default SiteMeta;
