import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import breakpoint from 'styled-components-breakpoint';

import Layout from 'components/page/Layout';
import SiteMeta from 'components/shared/SiteMeta';
import TimeStamp from 'components/shared/TimeStamp';
import Tags from 'components/shared/Tags';
import {
  DARK_JUNGLE_GREEN,
  REVOLVER,
  SUPERNOVA,
  WHITE,
} from 'constants/colors';
import { H2, Meta } from 'styles/article';
import logo from 'images/logo.jpg';

const Pagination = ({ pageContext, pagePrefix }) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? '/'
      : `${pagePrefix}/page/${(currentPage - 1).toString()}`;
  const nextPage = `${pagePrefix}/page/${(currentPage + 1).toString()}`;
  return (
    <List>
      {!isFirst && (
        <NavLink to={prevPage} rel="prev">
          <i className="chevron left icon" /> Previous
        </NavLink>
      )}
      {Array.from({ length: numPages }, (page, i) => (
        <Item key={`pagination-number${i + 1}`}>
          <ActiveLink
            isCurrent={i + 1 === currentPage}
            to={i === 0 ? `${pagePrefix}/` : `${pagePrefix}/page/${i + 1}`}
          >
            {i + 1}
          </ActiveLink>
        </Item>
      ))}
      {!isLast && (
        <NavLink to={nextPage} rel="next">
          Next <i className="chevron right icon" />
        </NavLink>
      )}
    </List>
  );
};

function Articles({
  content = null,
  posts,
  pageContext,
  pagePrefix = '',
  showHeader = true,
  showPagination = true,
  showRecentPosts = true,
}) {
  return (
    <>
      {showHeader && (
        <Header posts={posts}>
          <Img src={logo} alt="Logo" />
          <SiteMeta />
        </Header>
      )}
      <Layout recentPosts={showRecentPosts ? posts.slice(0, 5) : []}>
        <Main>
          {posts.map((post, index) => {
            const {
              frontmatter: {
                author,
                date,
                featuredImage,
                path,
                title,
                tags,
                excerpt,
              },
            } = post.node || post;

            return (
              <Article key={index}>
                <Wrapper>
                  <Link to={path}>
                    <Figure>
                      <FeaturedImage
                        src={featuredImage?.childImageSharp?.fixed?.src}
                        alt={title}
                      />
                    </Figure>
                  </Link>
                  <ArticleHeader>
                    <Tags tags={tags} />
                    <TimeStamp date={date} />
                    <Author>By {author}</Author>
                    <Link to={path}>
                      <H2>{title}</H2>
                    </Link>
                  </ArticleHeader>
                  <Excerpt to={path}>
                    <ShortDescription>{excerpt}</ShortDescription>
                  </Excerpt>
                </Wrapper>
              </Article>
            );
          })}
          <Content>{content}</Content>
          {showPagination && (
            <Pagination
              posts={posts}
              pageContext={pageContext}
              pagePrefix={pagePrefix}
            />
          )}
        </Main>
      </Layout>
    </>
  );
}

const Wrapper = styled.div`
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1;
  font-size: 3rem;
  text-align: center;
`;

const Figure = styled.figure`
  height: 11.2rem;
  opacity: 1;
  overflow: hidden;

  &:hover {
    opacity: 0.7;
    transition: all 0.3s ease-in;
    transform: scale(2, 2), opacity 0.3s ease-in-out;
    -moz-transform: opacity 0.3s ease-in-out;
    -webkit-transform: opacity 0.3s ease-in-out;
  }
`;

const Main = styled.div`
  margin-left: -3rem;
  display: flex;
  flex-wrap: wrap;
`;

const FeaturedImage = styled.img`
  width: 33rem;

  &:hover {
    transform: scale(1.06);
    -moz-transform: scale(1.06);
    -webkit-transform: scale(1.06);
  }
`;

const Header = styled.div`
  background: ${REVOLVER} !important;
  display: flex;
  flex-direction: column;
  padding: 3.6rem 1rem 1rem 1rem;

  ${breakpoint('tablet')`
    flex-direction: row;
    padding: 3.6rem 0 1rem 3.6rem;
  `}

  ${breakpoint('desktop')`
    flex-direction: row;
    padding: 4.6rem 0 1rem 18.75%;
  `}
`;

const Img = styled.img`
  border: 0.4rem solid ${WHITE} !important;
  border-radius: 50%;
  margin: 1.5rem auto 0;
  transition: transform 1s;

  ${breakpoint('mobile')`
    flex-direction: column;
    height: 10rem;
    width: 10rem;
  `}

  ${breakpoint('tablet')`
    flex-direction: row;
    height: 14rem;
    margin: 8rem 4rem;
    width: 14rem;
  `}

  ${breakpoint('desktop')`
    margin: 2rem 4rem;
  `}
`;

const Article = styled.article`
  display: inline-block;
  margin: 0 0 3rem 3rem;
  width: 33rem;
`;

const Excerpt = styled(Link)`
  color: ${DARK_JUNGLE_GREEN} !important;
  padding-left: 2rem;
`;

const ShortDescription = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding-bottom: 0 !important;
  margin-bottom: 1rem;
`;

const ArticleHeader = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

const Author = styled(Meta)``;

const List = styled.ul`
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  width: 100%;

  ${breakpoint('tablet')`
    display: flex;
  `}
`;

const Item = styled.li`
  margin: 0;
  margin-bottom: 0 !important;
`;

const ActiveLink = styled(Link)`
  background: ${(props) => props.isCurrent && SUPERNOVA};
  padding: 0.2rem 0.4rem;
  text-decoration: none;
  color: ${DARK_JUNGLE_GREEN};
`;

const NavLink = styled(Link)`
  color: ${DARK_JUNGLE_GREEN};
`;

export default Articles;
